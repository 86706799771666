import React from 'react'

import { Head } from '../../components/Head'
import { MultiPageFormExample } from '../../examples/react/pages/MultiPageFormExample'

const Page = () => (
  <>
    <Head title="Monisivuinen lomake" />
    <MultiPageFormExample />
  </>
)

export default Page
