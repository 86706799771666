import React, { useState } from 'react'
import { Link } from 'gatsby'

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Buttons,
  Checkbox,
  CheckboxGroup,
  Col,
  Columns,
  DescriptionList,
  DescriptionListItem,
  Detail,
  Divider,
  Focus,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Heading,
  Inline,
  Input,
  Lead,
  Main,
  Message,
  MessageHeader,
  Paragraph,
  PenIcon,
  PostponeIcon,
  Row,
  SideStep,
  SideSteps,
  SkipLink,
  Spacer,
  StatusBar,
  StatusBarItem,
  Textarea,
  Wrapper,
  XIcon
} from '@te-digi/styleguide'

import { FocusCollapsiblesExample } from '../FocusCollapsiblesExample'
import { HeaderExample } from '../HeaderExamples'
import { FooterExample } from '../FooterExample'

type ExampleProps = {
  className?: string
  step?: number
}

const getStep = () =>
  typeof window !== 'undefined'
    ? parseInt(window.location.hash.replace('#', '')) || 1
    : 1

const MultiPageFormExample = ({
  className,
  step = getStep()
}: ExampleProps) => {
  const [shouldFocus, setShouldFocus] = useState(false)

  const onStepClick = () => {
    setShouldFocus(true)
  }

  const onHeadingBlur = () => {
    setShouldFocus(false)
  }

  return (
    <>
      <SkipLink />
      <Wrapper className={className}>
        <HeaderExample
          activePrimaryItem="Omat sivut"
          hasUser
          hasCompany
        />
        <Columns
          layout="sidebar"
          sidebar={
            <div data-note="1">
              <SideSteps label="Lomakkeen pääotsikko">
                <SideStep
                  active={step === 1}
                  as={Link}
                  onClick={onStepClick}
                  to="#1"
                >
                  Ensimmäinen sivu
                </SideStep>
                <SideStep disabled>Toinen sivu</SideStep>
                <SideStep
                  active={step === 3}
                  as={Link}
                  onClick={onStepClick}
                  to="#3"
                >
                  Tietojen lähetys
                </SideStep>
              </SideSteps>
            </div>
          }
        >
          <Main>
            {step === 1 && (
              <div data-note="2">
                <Focus enabled={shouldFocus}>
                  <Heading
                    onBlur={onHeadingBlur}
                    tabIndex={-1}
                  >
                    Ensimmäinen sivu
                  </Heading>
                </Focus>
                <Spacer marginBottom="xxl">
                  <Lead>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla accumsan tortor nec est ornare bibendum. Etiam a
                    scelerisque nisl, vel aliquam libero. Phasellus vehicula
                    diam et placerat feugiat.
                  </Lead>
                </Spacer>
                <FormLayout>
                  <FormLayoutItem
                    header={
                      <>
                        <Heading
                          size={3}
                          level={2}
                        >
                          Ensimmäisen osion otsikko
                        </Heading>
                        <Paragraph>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nulla accumsan tortor nec est ornare bibendum.
                          Etiam a scelerisque nisl, vel aliquam libero.
                          Phasellus vehicula diam et placerat feugiat.
                        </Paragraph>
                      </>
                    }
                  >
                    <Row>
                      <Col
                        xs={12}
                        md={6}
                      >
                        <FormGroup>
                          <Detail heading="Aliquam">
                            Sed congue augue vitae neque
                          </Detail>
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                      >
                        <FormGroup>
                          <Detail heading="Erat volutpat">
                            Proin interdum maecenas massa
                          </Detail>
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Input
                        label="Lorem ipsum dolor sit amet"
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Textarea label="Nulla accumsan tortor" />
                    </FormGroup>
                    <CheckboxGroup
                      label="Phasellus vehicula diam"
                      onChange={() => {
                        // This is intentional
                      }}
                    >
                      <Checkbox>Etiam a scelerisque nisl</Checkbox>
                      <Checkbox>Vel aliquam libero</Checkbox>
                    </CheckboxGroup>
                  </FormLayoutItem>
                  <FormLayoutItem
                    header={
                      <>
                        <Heading
                          size={3}
                          level={2}
                        >
                          Toisen osion otsikko
                        </Heading>
                        <Paragraph>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nulla accumsan tortor nec est ornare bibendum.
                          Etiam a scelerisque nisl, vel aliquam libero.
                          Phasellus vehicula diam et placerat feugiat.
                        </Paragraph>
                      </>
                    }
                    color="none"
                  >
                    <FocusCollapsiblesExample />
                  </FormLayoutItem>
                </FormLayout>
              </div>
            )}
            {step === 3 && (
              <div data-note="3">
                <Focus enabled={shouldFocus}>
                  <Heading
                    onBlur={onHeadingBlur}
                    tabIndex={-1}
                  >
                    Tietojen lähetys
                  </Heading>
                </Focus>
                <Message attentionColor="secondary">
                  <MessageHeader>
                    <Heading
                      as="p"
                      level={3}
                      noMargin
                      size={4}
                    >
                      Tärkeä huomautettava asia
                    </Heading>
                  </MessageHeader>
                </Message>
                <Spacer marginBottom="xxl">
                  <Paragraph>
                    Ilmoitus käsitellään antamiesi vastausten perusteella.
                    Valitsemalla "Lähetä" vakuutat antamasi tiedot oikeiksi.
                  </Paragraph>
                </Spacer>
                <Heading
                  size={3}
                  level={3}
                >
                  Ensimmäinen sivu
                </Heading>
                <FormLayout>
                  <FormLayoutItem
                    color="white"
                    header={
                      <Inline
                        gap="md"
                        justifyContent="between"
                      >
                        <Heading
                          size={4}
                          level={4}
                        >
                          Ensimmäisen osion otsikko
                        </Heading>
                        <Button
                          iconLeft={<PenIcon />}
                          variant="plain"
                        >
                          Muokkaa
                        </Button>
                      </Inline>
                    }
                  >
                    <DescriptionList noMargin>
                      <DescriptionListItem
                        label="Lorem ipsum dolor"
                        labelWidth={4}
                      >
                        Sit amet consectetuer adipiscing elit
                      </DescriptionListItem>
                      <DescriptionListItem
                        label="Sed posuere"
                        labelWidth={4}
                      >
                        Interdum sem quisque
                      </DescriptionListItem>
                      <DescriptionListItem
                        label="Ligula eros"
                        labelWidth={4}
                      >
                        Ullamcorper quis lacinia
                      </DescriptionListItem>
                      <DescriptionListItem
                        label="Quis facilisis"
                        labelWidth={4}
                      >
                        Sed sapien mauris varius diam vitae arcu sed arcu lectus
                        auctor vitae, consectetuer et venenatis eget velit.
                      </DescriptionListItem>
                    </DescriptionList>
                  </FormLayoutItem>
                  <FormLayoutItem
                    header={
                      <Inline
                        gap="md"
                        justifyContent="between"
                      >
                        <Heading
                          size={4}
                          level={4}
                        >
                          Toisen osion otsikko
                        </Heading>
                        <Button
                          iconLeft={<PenIcon />}
                          variant="plain"
                        >
                          Muokkaa
                        </Button>
                      </Inline>
                    }
                    color="white"
                  >
                    <DescriptionList noMargin>
                      <DescriptionListItem
                        label="Mauris sed libero"
                        labelWidth={4}
                      >
                        Suspendisse facilisis
                      </DescriptionListItem>
                      <DescriptionListItem
                        label="Nulla in lacinia"
                        labelWidth={4}
                      >
                        Laoreet lorem velit accumsan
                      </DescriptionListItem>
                      <DescriptionListItem
                        label="Velit"
                        labelWidth={4}
                      >
                        Vel mattis libero
                      </DescriptionListItem>
                      <DescriptionListItem
                        label="Nisl et sem"
                        labelWidth={4}
                      >
                        Proin interdum maecenas massa turpis sagittis in,
                        interdum non lobortis vitae massa. Quisque purus lectus,
                        posuere eget imperdiet nec sodales id arcu. Vestibulum
                        elit pede dictum eu, viverra non tincidunt eu ligula.
                      </DescriptionListItem>
                    </DescriptionList>
                  </FormLayoutItem>
                </FormLayout>
                <Divider />
                <Heading
                  size={3}
                  level={3}
                >
                  Toinen sivu
                </Heading>
                <FormLayout>
                  <FormLayoutItem
                    color="white"
                    header={
                      <Inline
                        gap="md"
                        justifyContent="between"
                      >
                        <Heading
                          size={4}
                          level={4}
                        >
                          Ensimmäisen osion otsikko
                        </Heading>
                        <Button
                          iconLeft={<PenIcon />}
                          variant="plain"
                        >
                          Muokkaa
                        </Button>
                      </Inline>
                    }
                  >
                    <DescriptionList noMargin>
                      <DescriptionListItem
                        label="Lorem ipsum dolor"
                        labelWidth={4}
                      >
                        Sit amet consectetuer adipiscing elit
                      </DescriptionListItem>
                      <DescriptionListItem
                        label="Sed posuere"
                        labelWidth={4}
                      >
                        Interdum sem quisque
                      </DescriptionListItem>
                      <DescriptionListItem
                        label="Ligula eros"
                        labelWidth={4}
                      >
                        Ullamcorper quis lacinia
                      </DescriptionListItem>
                      <DescriptionListItem
                        label="Quis facilisis"
                        labelWidth={4}
                      >
                        Sed sapien mauris varius diam vitae arcu sed arcu lectus
                        auctor vitae, consectetuer et venenatis eget velit.
                      </DescriptionListItem>
                    </DescriptionList>
                  </FormLayoutItem>
                  <FormLayoutItem
                    header={
                      <Inline
                        gap="md"
                        justifyContent="between"
                      >
                        <Heading
                          size={4}
                          level={4}
                        >
                          Toisen osion otsikko
                        </Heading>
                        <Button
                          iconLeft={<PenIcon />}
                          variant="plain"
                        >
                          Muokkaa
                        </Button>
                      </Inline>
                    }
                    color="white"
                  >
                    <DescriptionList noMargin>
                      <DescriptionListItem
                        label="Mauris sed libero"
                        labelWidth={4}
                      >
                        Suspendisse facilisis
                      </DescriptionListItem>
                      <DescriptionListItem
                        label="Nulla in lacinia"
                        labelWidth={4}
                      >
                        Laoreet lorem velit accumsan
                      </DescriptionListItem>
                      <DescriptionListItem
                        label="Velit"
                        labelWidth={4}
                      >
                        Vel mattis libero
                      </DescriptionListItem>
                      <DescriptionListItem
                        label="Nisl et sem"
                        labelWidth={4}
                      >
                        Proin interdum maecenas massa turpis sagittis in,
                        interdum non lobortis vitae massa. Quisque purus lectus,
                        posuere eget imperdiet nec sodales id arcu. Vestibulum
                        elit pede dictum eu, viverra non tincidunt eu ligula.
                      </DescriptionListItem>
                    </DescriptionList>
                  </FormLayoutItem>
                </FormLayout>
              </div>
            )}
            <div data-note="4">
              {step === 1 && <Divider />}
              {step === 3 && <Spacer marginBottom="xl" />}
              <Row justifyContent="between">
                <Col xs="auto">
                  {step === 3 && (
                    <Button
                      as={Link}
                      iconLeft={<ArrowLeftIcon />}
                      onClick={onStepClick}
                      to="#1"
                      variant="outline"
                    >
                      Edellinen
                    </Button>
                  )}
                </Col>
                <Col xs="auto">
                  {step === 1 && (
                    <Button
                      as={Link}
                      iconRight={<ArrowRightIcon />}
                      onClick={onStepClick}
                      to="#3"
                    >
                      Seuraava
                    </Button>
                  )}
                </Col>
              </Row>
              <Divider />
              <Buttons align="center">
                <Button
                  iconLeft={<XIcon />}
                  variant="plain"
                >
                  Keskeytä
                </Button>
                <Button
                  iconLeft={<PostponeIcon />}
                  variant="plain"
                >
                  Jatka myöhemmin
                </Button>
                {step === 3 && <Button color="secondary">Lähetä</Button>}
              </Buttons>
            </div>
          </Main>
        </Columns>
        <StatusBar
          data-id="statusbar"
          saveStatus="saved"
        >
          <StatusBarItem>
            Et ole vielä lähettänyt ilmoittautumista
          </StatusBarItem>
        </StatusBar>
        <FooterExample />
      </Wrapper>
    </>
  )
}

export { MultiPageFormExample }
